<template>
  <div class="p-payment">
    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" />

    <div class="list">
      <div class="list__item list__col-name list__item--border-none">
        <div class="col--1">
          <p class="list__col-name">Lp.</p>
        </div>
        <div class="col--2">
          <p class="list__col-name">ID</p>
        </div>
        <div class="col--3">
          <p class="list__col-name">Imię i nazwisko</p>
        </div>
        <div class="col--1">
          <p class="list__col-name">Kwota</p>
        </div>
        <div class="col--1">
          <p class="list__col-name">Kwota</p>
        </div>
      </div>
      <div class="list__item" v-for="(item, key) in response" :key="key">
        <div class="col--1">
          <p class="list__elem">{{ key + 1 }}.</p>
        </div>
        <div class="col--2">
          <p class="list__elem">{{ findUserEvent(item.id).paradiso_id }}</p>
        </div>
        <div class="col--3">
          <p class="list__elem">{{ findUserEvent(item.id).name }} {{ findUserEvent(item.id).surname }}</p>
        </div>
        <div class="col--1">
          <p class="list__elem">
            {{ item.type_id == 1 ? "Przelew" : "Gotówka" }}
          </p>
        </div>
        <div class="col--1">
          <p class="list__elem list__elem--green">
            {{ item.paid_in == null ? "-" : item.paid_in }} zł
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
// import Paginate from "@/components/elements/Paginate.vue";

export default {
  components: {},
  data() {
    return {
      file: null,
      response: null,
    };
  },
  computed: {
    ...mapGetters({
      eventUsers: "EventUsers/getEventUsers",
      registrationInfo: "EventUsers/getRegistrationInfo",
    }),
  },
  methods: {
    ...mapActions({
      fetchEventUsers: "EventUsers/fetchEventUsers",
      fetchRegistrationInfo: "EventUsers/fetchRegistrationInfo",
      deleteEventUsers: "EventUsers/deleteEventUsers",
      edit: "EventUsers/saveEdit",
      saveBookUser: "EventUsers/saveBookUser",
      searchEventUsers: "EventUsers/searchEventUsers",
    }),
    async handleFileUpload() {
      let file = this.$refs.file.files[0];
      console.log(file);
      if (file.name.split(".").pop() == "csv") {
        let formData = new FormData();
        formData.append("files", file);

        const response = await axios.post(`/payment/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response);
        this.response = response.data;
      } else {
        console.log("Wymagany plik z rozszerzeniem csv");
      }
    },
    findUserEvent(id) {
      return this.eventUsers.data.find((e) => e.id == id);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.userDetails {
  &__item {
    margin-bottom: 1rem;
  }
  &__title {
    text-transform: uppercase;
    border-bottom: 1px solid $green;
    margin: 1.5rem -1rem;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    margin-top: 2.5rem;
    &--first {
      margin-top: 0;
    }
  }
}
</style>
